/**
 * @file Composable to scroll to selected element and track visible elements
 */

import { getNearestScrollingElement, scrollToTopOf } from '@@/bits/dom'
import { captureMessage } from '@@/bits/error_tracker'
import { vSet } from '@@/bits/vue'
import type { Ref } from 'vue'
import { nextTick, onUnmounted, ref, watch } from 'vue'

const DEFAULT_SCROLL_DURATION = 200
const WAIT_ANIMATION_DELAY = 100

interface ScrollRefs {
  selectedElementKey: Ref<string | undefined>
  scrollToElement: (elementKey: string) => void
  startObserving: () => void
}

function isInputValid(elements: HTMLElement[]): boolean {
  if (elements.length === 0) {
    return false
  }

  if (getNearestScrollingElement(elements[0]) == null) {
    captureMessage(
      'These elements should be inside a parent with a class that enables scrolling, such as "overflow-auto".',
    )
    return false
  }

  for (const element of elements) {
    if (element.dataset.key === undefined) {
      captureMessage(`${JSON.stringify(element)} does not have a :data-key attribute.`)
      return false
    }
  }

  return true
}

/**
 * This composable will observe the visibility of different elements
 * and keep track of the top most visible element.
 *
 * If scrollToElement is manually triggered,
 * it will set the selectedElementKey to the elementKey and try to scroll to it.
 * @param {Ref<HTMLElement[]>} elements - Ref of an array of HTML elements to observe. Each of them must have a unique :data-key attribute in the list.
 * @param {number} scrollDuration - Duration of the scroll animation
 * @returns {ScrollRefs} {selectedKey, scrollToElement} - Ref of the key of the top most visible element & Function to scroll to a specific element
 */
export const useObserveVisibleElementOnScroll = (
  elements: Ref<HTMLElement[]>,
  scrollDuration = DEFAULT_SCROLL_DURATION,
): ScrollRefs => {
  const selectedElementKey = ref()

  watch(
    elements,
    () => {
      nextTick(() => {
        if (isInputValid(elements.value)) {
          observer?.disconnect()
          startObserving()
        }
      })
    },
    { immediate: true },
  )

  const visibleElements = ref(Array(elements.value.length).fill(false))
  const isScrollingToElement = ref(false)

  let observer: IntersectionObserver

  function scrollToElement(elementKey: string): void {
    const selectedKeyIndex = elements.value.findIndex((item) => item.dataset.key === elementKey)
    const selectedKey = elements.value[selectedKeyIndex]

    if (selectedKey != null) {
      isScrollingToElement.value = true
      visibleElements.value = Array(elements.value.length).fill(false)
      vSet(visibleElements.value, selectedKeyIndex, true)

      selectedElementKey.value = elementKey
      scrollToTopOf(selectedKey, scrollDuration)

      setTimeout(() => {
        isScrollingToElement.value = false
      }, scrollDuration + WAIT_ANIMATION_DELAY)
    }
  }

  function startObserving(): void {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = elements.value.findIndex((el) => el.dataset.key === (entry.target as HTMLElement).dataset.key)
          if (index !== -1 && !isScrollingToElement.value) {
            vSet(visibleElements.value, index, entry.isIntersecting)
            const selectedElementKeyIndex = visibleElements.value.indexOf(true)
            if (selectedElementKeyIndex !== -1) {
              selectedElementKey.value = elements.value[selectedElementKeyIndex].dataset.key
            }
          }
        })
      },
      {
        threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
      },
    )
    elements.value.forEach((item) => {
      observer?.observe(item)
    })
  }

  onUnmounted(() => {
    observer?.disconnect()
  })

  return {
    selectedElementKey,
    scrollToElement,
    startObserving,
  }
}
